import React from 'react'
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { BtnWrapper, FormWrapper, BoxWrapper, BoxinputWrapper} from "./form.style";
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'
import { FormpageWrapper }from './form.style';
import Icon from 'react-icons-kit';
import {ic_keyboard_arrow_right} from 'react-icons-kit/md/ic_keyboard_arrow_right';
import {ic_keyboard_arrow_left} from 'react-icons-kit/md/ic_keyboard_arrow_left';
import Spinner from 'react-bootstrap/Spinner';
import { navigate } from 'gatsby';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const element = (
  <Spinner animation="border" size="sm" />
);

const animationblock = (data) => (
  <div className="animation-block">
    <div className="iframe-container">
        <div className="stepthead"
          dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.formData.step5Text.json) }}
        />
        <iframe 
        scrolling="no" 
        // src="https://nervous-pare-2190ea.netlify.com/"
        src="https://compassionate-hermann-d02c33.netlify.app/" 
        frameborder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
        </iframe>
    </div>
  </div>
);

class FormFive extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    console.log(window.history.state)
    if(!window.history.state.plan) {
      navigate('/');
    }else {
      const { formData } = this.props
      setTimeout(() => {
        navigate(formData.step6SlugUrl,
          {
            state: {
              plan: window.history.state.plan,
              planqty: window.history.state.planqty,
              userexpected: window.history.state.userexpected,
              invoicesinmonth: window.history.state.invoicesinmonth,
            },
          }
        );
      }, 9999)
      
    }
    
  }

  render() {
    const data = this.props;
    return (
      <React.Fragment>
        <FormpageWrapper>
          <Row>
            <Container fluid={true}>
              <Row>
                <Col xs="12" sm="9">
                  <div className="left-block">
                      <Row className="justify-content-md-center">
                        <Col xs="12" sm="6">
                            <span> {animationblock(data)} </span>
                        </Col>
                      </Row>
                  </div>
                </Col>
                <Col xs="12" sm="3">
                  <div className="right-block">
                    <div className="blackbox">
                     <h3 className="sidebartitle">{data.formData.sideBarTitle}</h3>
                       <div className="sidebarimages">
                         <ul>
                              {data.formData.sideBarLogos.map((items, i) => (
                                 <li>
                                  <Img fluid={items.fluid} />
                                </li>
                              ))} 
                          </ul>
                       </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </FormpageWrapper>
      </React.Fragment>
    );
  }
}

export default FormFive;
